namespace $ {
	export class $hotger_ads_app extends $mol_book2_catalog {
		
		/**
		 * ```tree
		 * plugins / <= Theme
		 * ```
		 */
		plugins() {
			return [
				this.Theme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * param \site
		 * ```
		 */
		param() {
			return "site"
		}
		
		/**
		 * ```tree
		 * menu_title @ \Sites
		 * ```
		 */
		menu_title() {
			return this.$.$mol_locale.text( '$hotger_ads_app_menu_title' )
		}
		
		/**
		 * ```tree
		 * menu_tools <= catalog_tools
		 * ```
		 */
		menu_tools() {
			return this.catalog_tools()
		}
		
		/**
		 * ```tree
		 * spreads *
		 * ```
		 */
		spreads() {
			return {
			}
		}
		
		/**
		 * ```tree
		 * Placeholder null
		 * ```
		 */
		Placeholder() {
			return null as any
		}
		
		/**
		 * ```tree
		 * Theme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Theme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Lighter $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		Lighter() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * logout?event null
		 * ```
		 */
		@ $mol_mem
		logout(event?: any) {
			if ( event !== undefined ) return event as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Minor_icon_only_icon $mol_icon_logout
		 * ```
		 */
		@ $mol_mem
		Minor_icon_only_icon() {
			const obj = new this.$.$mol_icon_logout()
			
			return obj
		}
		
		/**
		 * ```tree
		 * logout_icon_button $mol_button_minor
		 * 	click?event <= logout?event
		 * 	sub / <= Minor_icon_only_icon
		 * ```
		 */
		@ $mol_mem
		logout_icon_button() {
			const obj = new this.$.$mol_button_minor()
			
			obj.click = (event?: any) => this.logout()
			obj.sub = () => [
				this.Minor_icon_only_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * catalog_tools /
		 * 	<= Lighter
		 * 	<= logout_icon_button
		 * ```
		 */
		catalog_tools() {
			return [
				this.Lighter(),
				this.logout_icon_button()
			] as readonly any[]
		}
	}
	
	export class $hotger_ads_page extends $mol_page {
		
		/**
		 * ```tree
		 * title <= data
		 * ```
		 */
		title() {
			return this.data()
		}
		
		/**
		 * ```tree
		 * foot / <= buttons
		 * ```
		 */
		foot() {
			return [
				this.buttons()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body / <= Content
		 * ```
		 */
		body() {
			return [
				this.Content()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * data \loading
		 * ```
		 */
		data() {
			return "loading"
		}
		
		/**
		 * ```tree
		 * update_content?val null
		 * ```
		 */
		@ $mol_mem
		update_content(val?: any) {
			if ( val !== undefined ) return val as never
			return null as any
		}
		
		/**
		 * ```tree
		 * apply_button $mol_button_typed
		 * 	title \safe changes
		 * 	event_click?val <=> update_content?val
		 * 	attr * mol_theme \$mol_theme_base
		 * ```
		 */
		@ $mol_mem
		apply_button() {
			const obj = new this.$.$mol_button_typed()
			
			obj.title = () => "safe changes"
			obj.event_click = (val?: any) => this.update_content(val)
			obj.attr = () => ({
				mol_theme: "$mol_theme_base"
			})
			
			return obj
		}
		
		/**
		 * ```tree
		 * drop_changes_content?val null
		 * ```
		 */
		@ $mol_mem
		drop_changes_content(val?: any) {
			if ( val !== undefined ) return val as never
			return null as any
		}
		
		/**
		 * ```tree
		 * drop_changes_button $mol_button_major
		 * 	title \drop changes
		 * 	event_click?val <=> drop_changes_content?val
		 * ```
		 */
		@ $mol_mem
		drop_changes_button() {
			const obj = new this.$.$mol_button_major()
			
			obj.title = () => "drop changes"
			obj.event_click = (val?: any) => this.drop_changes_content(val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * buttons $mol_bar sub /
		 * 	<= apply_button
		 * 	<= drop_changes_button
		 * ```
		 */
		@ $mol_mem
		buttons() {
			const obj = new this.$.$mol_bar()
			
			obj.sub = () => [
				this.apply_button(),
				this.drop_changes_button()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * content?val \
		 * ```
		 */
		@ $mol_mem
		content(val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * Content $mol_string
		 * 	dom_name \textarea
		 * 	value?val <=> content?val
		 * ```
		 */
		@ $mol_mem
		Content() {
			const obj = new this.$.$mol_string()
			
			obj.dom_name = () => "textarea"
			obj.value = (val?: any) => this.content(val)
			
			return obj
		}
	}
	
}

