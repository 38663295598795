namespace $ {
	export class $hotger_ads_auth extends $mol_page {
		
		/**
		 * ```tree
		 * title \Auth page
		 * ```
		 */
		title() {
			return "Auth page"
		}
		
		/**
		 * ```tree
		 * tools / <= theme_toggle
		 * ```
		 */
		tools() {
			return [
				this.theme_toggle()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body / <= Aftch
		 * ```
		 */
		body() {
			return [
				this.Aftch()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * foot / <= foter_text
		 * ```
		 */
		foot() {
			return [
				this.foter_text()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * theme_toggle $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		theme_toggle() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * login_text $mol_text text \Enter username
		 * ```
		 */
		@ $mol_mem
		login_text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => "Enter username"
			
			return obj
		}
		
		/**
		 * ```tree
		 * login_string $mol_string
		 * ```
		 */
		@ $mol_mem
		login_string() {
			const obj = new this.$.$mol_string()
			
			return obj
		}
		
		/**
		 * ```tree
		 * password_text $mol_text text \Enter password
		 * ```
		 */
		@ $mol_mem
		password_text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => "Enter password"
			
			return obj
		}
		
		/**
		 * ```tree
		 * password_string $mol_password
		 * ```
		 */
		@ $mol_mem
		password_string() {
			const obj = new this.$.$mol_password()
			
			return obj
		}
		
		/**
		 * ```tree
		 * login_button?val null
		 * ```
		 */
		@ $mol_mem
		login_button(val?: any) {
			if ( val !== undefined ) return val as never
			return null as any
		}
		
		/**
		 * ```tree
		 * login_button_m $mol_button_minor
		 * 	title \Login
		 * 	event_click?val <=> login_button?val
		 * ```
		 */
		@ $mol_mem
		login_button_m() {
			const obj = new this.$.$mol_button_minor()
			
			obj.title = () => "Login"
			obj.event_click = (val?: any) => this.login_button(val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Aftch $mol_list rows /
		 * 	<= login_text
		 * 	<= login_string
		 * 	<= password_text
		 * 	<= password_string
		 * 	<= login_button_m
		 * ```
		 */
		@ $mol_mem
		Aftch() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.login_text(),
				this.login_string(),
				this.password_text(),
				this.password_string(),
				this.login_button_m()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * foter_text $mol_text text \(c) Ads
		 * ```
		 */
		@ $mol_mem
		foter_text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => "(c) Ads"
			
			return obj
		}
	}
	
}

