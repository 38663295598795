namespace $ {
	export class $hotger_ads extends $mol_view {
		
		/**
		 * ```tree
		 * plugins / <= Themme
		 * ```
		 */
		plugins() {
			return [
				this.Themme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Auth $hotger_ads_auth
		 * ```
		 */
		@ $mol_mem
		Auth() {
			const obj = new this.$.$hotger_ads_auth()
			
			return obj
		}
		
		/**
		 * ```tree
		 * App $hotger_ads_app
		 * ```
		 */
		@ $mol_mem
		App() {
			const obj = new this.$.$hotger_ads_app()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Themme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Themme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
	}
	
}

