namespace $.$$ {
  export class $hotger_ads extends $.$hotger_ads {
    sub() {
      if (this.App().logined()) {
        return [
          this.App(),
        ];
      } else {
        return [
          this.Auth(),
        ];
      }
    }
  }
}
