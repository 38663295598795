namespace $.$$ {
  export class $hotger_ads_app extends $.$hotger_ads_app {
    @ $mol_mem
    logined(id?: boolean) {
      return ($mol_state_local.value('accessToken') !== null || undefined) ? true : false;
    }

    logout() {
      $mol_state_local.value('accessToken', null);
    }

    spreads() {
      return [
        new $hotger_ads_page("2conv.com"),
        new $hotger_ads_page("flvto.biz"),
        new $hotger_ads_page("flv2mp3.by"),
        new $hotger_ads_page("flvto.com.mx"),

        new $hotger_ads_page("flv-to.com"),
        new $hotger_ads_page("youtubetomusic.org"),
        new $hotger_ads_page("allyoutube.org"),
        new $hotger_ads_page("convert-to-mp3.biz"),
        new $hotger_ads_page("youtubeservices.top"),

        new $hotger_ads_page("f3mp3.com"),
        new $hotger_ads_page("youtubemp3donusturucu.net"),
        new $hotger_ads_page("youtube-mp3-music.com"),
        new $hotger_ads_page("video-to-mp3-converter.com"),
        new $hotger_ads_page("Flvconverter.org"),
        new $hotger_ads_page("flv2all.com"),
        new $hotger_ads_page("stacigraymusic.com"),
        new $hotger_ads_page("sparkmp3.com"),
        new $hotger_ads_page("flvto.bz"),

        new $hotger_ads_page("hotger.games"),
      ];
    }
  }

  export class $hotger_ads_page extends $.$hotger_ads_page {
    @ $mol_wire_solo
    data(val?: any) {
      if (val !== undefined) return val as never;
      return "loading";
    }

    constructor(file_name?: any) {
      super();
      this.data(file_name);
    }

    update_content() {
      const token = $mol_state_local.value('accessToken') as string
      const responce = $mol_fetch.text('/ads/' + this.data(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'accessToken': token
        },
        body: JSON.stringify({ 'text': this.content() })
      });
      alert(responce);
    }

    drop_changes_content() {
      this.content(this.fetch_content());
    }

    @ $mol_wire_solo
    content(val?: any) {
      if (val !== undefined) return val as never;
      return this.fetch_content();
    }

    fetch_content() {
      return $mol_fetch.text(this.data());
    }
  }
}
